import logo from "./logo.svg";
import "./App.css";
import { ConfigProvider } from "antd";
import IndexRoutes from "./routes/IndexRoutes";

function App() {
  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            headerBg: "#04364A",
            headerSortActiveBg: "#04364A",
            headerSortHoverBg: "#176B87",
            // footerBg: "#04364A",
            fixedHeaderSortActiveBg: "#176B87",
            // rowExpandedBg: "rgb(25, 25, 25)",
            headerColor: "rgb(255, 255, 255)",
            filterDropdownBg: "#DAFFFB",
            filterDropdownMenuBg: "#DAFFFB",
            expandIconBg: "#DAFFFB",
            headerBorderRadius: 12,
            // rowHoverBg: "#000000",
          },
          Menu: {
            itemColor: "rgb(255, 255, 255)",
            itemSelectedColor: "#B6BBC4",
            itemSelectedBg: "#31304D",

            itemHoverColor: "rgb(0, 0, 0,50)",
            itemHoverBg: "rgb(218, 255, 251)",
            itemActiveBg: "#64CCC5",
            groupTitleColor: "rgb(255, 255, 255)",
            darkGroupTitleColor: "rgb(255, 255, 255)",
          },
          Modal: {
            // contentBg: "#64CCC5",
            headerBg: "#04364A",
            titleColor: "rgb(255,255,255)",
            titleLineHeight: 2,
          },
          Pagination: {
            itemSize: 32,
            // itemSizeSM: 50,
          },
        },
      }}
    >
      <IndexRoutes />
    </ConfigProvider>
  );
}

export default App;
