import axios from "axios";
import config from "../config/global";

export const ApiClient = () => {
  // Create a new axios instance
  const api = axios.create({
    baseURL: config.BASEURL,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  // Add a request interceptor to add the JWT token to the authorization header
  api.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("token");
      if (token) {
        config.headers.Authorization = `Bearer XCKLerlJMN33HiPOWnxNexerXsSbTG`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  // Add a response interceptor to refresh the JWT token if it's expired
  api.interceptors.response.use(
    (response) => response,
    (error) => {
      const originalRequest = error.config;

      // If the error is a 401 and we have a refresh token, refresh the JWT token
      if (error.response.status === 401 && localStorage.getItem("refresh")) {
        const refreshToken = localStorage.getItem("refresh");

        let data = JSON.stringify({
          refresh_token: refreshToken,
        });

        post("/refreshToken", data)
          .then((response) => {
            localStorage.setItem("toke", response.token);
            localStorage.setItem("refresh", response.refresh_token);

            // Re-run the original request that was intercepted
            originalRequest.headers.Authorization = `Bearer ${response.token}`;
            api(originalRequest)
              .then((response) => {
                return response.data;
              })
              .catch((error) => {
                console.log(error);
              });
            // return api(originalRequest)
          })
          .catch((err) => {
            // If there is an error refreshing the token, log out the user
            console.log(err);
          });
      }

      // Return the original error if we can't handle it
      return Promise.reject(error);
    }
  );

  const login = async (path, data) => {
    return await api
      .post(path, data)
      .then(({ data }) => {
        // Store the JWT and refresh tokens in session storage
        return data;
      })
      .catch((err) => {
        // Return the error if the request fails
        return err;
      });
  };

  const get = async (path) => {
    return await api.get(path).then((response) => response.data);
  };

  const post = async (path, data) => {
    return await api.post(path, data).then((response) => response.data);
  };

  const put = async (path, data) => {
    return await api.put(path, data).then((response) => response.data);
  };

  const patch = async (path, data) => {
    return await api.patch(path, data).then((response) => response.data);
  };

  const del = async (path) => {
    return await api.delete(path).then((response) => response.data);
  };

  return {
    login,
    get,
    post,
    put,
    patch,
    del,
  };
};
