import { Col, Form, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import FormPoli from "../../components/form/FormPoli";
import TablePenunjang from "../../components/table/TablePenunjang";
import TableNonPenunjang from "../../components/table/TableNonPenunjang";
import TablePoli from "../../components/table/TablePoli";
import { ApiClient } from "../../api/ApiClient";

function PoliAnak() {
  const [dataForm, setDataForm] = useState([]);

  const getDataTable = () => {
    ApiClient()
      .get(
        "/run_wait_result/f/u/tigaresi/delicate_flow?payload=eyJkYXRhIjp7ImRldmljZSI6ImQifX0%3D"
      )
      .then((res) => {
        setDataForm(res);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getDataTable();
    // return getDataTable();
  }, []);
  return (
    <Row
      style={{ paddingLeft: "10px", margin: "5px", justifyContent: "center" }}
    >
      <Row>
        <FormPoli getDataTable={getDataTable} poli={"Poli Anak"} />
      </Row>
      <TablePoli data={dataForm} />
      <TablePenunjang data={dataForm} />
      {/* <Row>
        <TablePenunjang />
        <TableNonPenunjang />
       
      </Row> */}
    </Row>
  );
}

export default PoliAnak;
