import React, { useState, useMemo } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import PoliAnak from "../pages/poli/PoliAnak";
import Sidebar from "../components/shared/Sidebar";
import LoginPage from "../pages/LoginPage";
import DashboardPage from "../pages/DashboardPage";

function IndexRoutes() {
  const token = localStorage.getItem("token");
  return (
    <div>
      <BrowserRouter>
        {token ? (
          <Sidebar>
            <Routes>
              <Route path="*" element={<LoginPage />} />
              <Route
                path="/"
                element={
                  <ProtectedRoute>
                    <DashboardPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/poli-anak"
                element={
                  <ProtectedRoute>
                    <PoliAnak />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </Sidebar>
        ) : (
          <Routes>
            {/* <Route path="*" element={<LoginPage />} /> */}
            <Route
              path="/"
              element={<ProtectedRoute>{/* <HomePage /> */}</ProtectedRoute>}
            />
            <Route path="/login" element={<LoginPage />} />
          </Routes>
        )}
      </BrowserRouter>
    </div>
  );
}

export default IndexRoutes;
