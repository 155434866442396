import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  StockOutlined,
  LogoutOutlined,
  IdcardOutlined,
  UserAddOutlined,
  FileTextOutlined,
  EnvironmentOutlined,
  GlobalOutlined,
  HomeOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import {
  Layout,
  Menu,
  Button,
  theme,
  Typography,
  Affix,
  Col,
  Space,
  ConfigProvider,
} from "antd";
import { Footer } from "antd/es/layout/layout";
const { Header, Sider, Content } = Layout;

function Sidebar({ children }) {
  const [collapsed, setCollapsed] = useState(false);
  const [item, setItem] = useState([]);
  const fn = localStorage.getItem("fn");
  const navigate = useNavigate();
  // const {
  //   token: { colorBgContainer },
  // } = theme.useToken();

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }
  useEffect(() => {
    if (localStorage.getItem("credent") !== null) {
      var role = localStorage.getItem("credent");

      if (role === "3" || role === "4") {
        setItem([
          getItem("Dashboard", "/", <StockOutlined />),
          getItem("POLI", "poli", <GlobalOutlined />, [
            getItem("POLI ANAK", "/poli-anak"),
            getItem("POLI BEDAH", "/site-list1"),
            getItem("POLI GIGI", "/site-list2"),
            getItem("POLI KEBIDANAN", "/site-list3"),
            getItem("POLI KULIT KELAMIN", "/site-list4"),
          ]),
          // getItem("Gate", "gate", <MergeCellsOutlined />, [
          //   getItem("Gate Registration", "/gate-registration"),
          //   getItem("Gate List", "/gate-list"),
          // ]),
          getItem("UMUM", "umum", <UserAddOutlined />, [
            getItem("DIKLAT", "/user-registration"),
            getItem("FISIO", "/user-list"),
          ]),
          // getItem(
          //   "Account",
          //   "/account-list",
          //   <IdcardOutlined />
          //   // [
          //   //   getItem("Card Registration", "/card-registration"),
          //   //   getItem("Card List", "/card-list"),
          //   // ]
          // ),
          // getItem("Log", "/log", <FileTextOutlined />),
        ]);
      } else if (role === "1") {
        setItem([
          {
            key: "/",
            icon: <HomeOutlined />,
            label: "Dashboard",

            // disabled: selector,
          },
          {
            key: "/log",
            icon: <FileTextOutlined />,
            label: "Log",
            // disabled: selector,
          },
          {
            key: "/user-info",
            icon: <InfoCircleOutlined />,
            label: "Info User",
          },
        ]);
      } else if (role === "2") {
        setItem([getItem("Account", "/account-list", <IdcardOutlined />)]);
      }
    }
  }, []);

  return (
    <Layout
      className="layout-main"
      style={{
        backgroundColor: "black",
        minHeight: "100vh",
      }}
    >
      <Sider
        // theme="dark"
        style={{ backgroundColor: "#04364A", width: "100%" }}
        trigger={null}
        width={250}
        collapsible
        collapsed={collapsed}
      >
        <div className="load-sidebar">
          <Affix>
            <Menu
              // theme="dark"
              mode="inline"
              color="#04364A"
              style={{ backgroundColor: "#04364A" }}
              // xxl={24}
              // xs={2}
              defaultSelectedKeys={window.location.pathname}
              // defaultOpenKeys={[window.location.pathname]}
              items={item}
              onClick={({ key }) => {
                // setMenu(key);
                navigate(key);

                // fecthData(key);
              }}
            />
          </Affix>
        </div>
      </Sider>

      <Layout style={{ backgroundColor: "#04364A", flex: 1 }}>
        <div className="load-sidebar">
          <Affix>
            <Header
              style={{
                margin: 0,
                padding: 0,
                height: 50,
                paddingLeft: "10px",
                backgroundColor: "#04364A",
                justifyContent: "space-between",
                // borderColor: "red",
                borderWidth: "10px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Col span={8}>
                {/* <Row align="middle"> */}
                <Button
                  type="text"
                  icon={
                    collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />
                  }
                  onClick={() => setCollapsed(!collapsed)}
                  style={{
                    fontSize: "16px",
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                    paddingTop: "15px",
                    color: "white",
                  }}
                />
              </Col>
              {/* <Col
                span={8}
                md={() => {
                  console.log("first");
                }}
                xxl={8}
                style={{
                  display: "flex",
                  alignItems: "center",
                  alignContent: "center",
                  justifyItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography.Title
                  style={{
                    alignSelf: "center",
                    // display: "flex",
                    color: "white",
                    paddingTop: "25px",
                    paddingBottom: "10px",
                    // fontFamily: "sans-serif",
                  }}
                >
                  PORTAL LOCK
                </Typography.Title>
              </Col> */}
              <Col
                span={14}
                // xxl={4}
                md={8}
                style={{
                  paddingRight: "10px",
                  display: "flex",
                  alignItems: "center",
                  alignContent: "center",
                  justifyItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <Space size="middle">
                  <Typography.Text
                    style={{
                      color: "white",
                      fontSize: "16px",
                    }}
                  >
                    Hii, {fn}
                  </Typography.Text>
                  {/* <Button
                    icon={<LogoutOutlined />}
                    shape="circle"
                    style={{
                      backgroundColor: "#e00202",
                    }}
                    onClick={() => {
                      localStorage.clear();
                      navigate("/login");
                      const time = setTimeout(() => {
                        window.location.reload(false);
                      }, 100);

                      return () => clearTimeout(time);
                    }}
                  /> */}
                  <button
                    className="logout-button"
                    onClick={() => {
                      localStorage.clear();
                      navigate("/login");
                      const time = setTimeout(() => {
                        window.location.reload(false);
                      }, 1000);

                      return () => clearTimeout(time);
                    }}
                  >
                    <div className="logout-sign">
                      <svg viewBox="0 0 512 512">
                        <path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"></path>
                      </svg>
                    </div>

                    <div className="logout-text">Logout</div>
                  </button>
                </Space>
              </Col>

              {/* </Row> */}
            </Header>
          </Affix>
        </div>

        <Content
          style={{
            padding: "10px",
            // paddingLeft: "10",
            margin: "5px",
            alignContent: "space-around",
            backgroundColor: "#64CCC5",
          }}
        >
          {children}
        </Content>
        <Affix offsetBottom="0" style={{ width: "100%" }}>
          <Footer
            style={{
              margin: 0,
              padding: 10,
              // height: 30,
              backgroundColor: "#04364A",

              // justifyContent: "space-between",
              // borderColor: "red",
              // borderWidth: "10px",
              // display: "flex",
              // flexDirection: "row",
            }}
          >
            <div className="footer-text">
              © 2023 TIGARESI BANGUN NUSAPERDANA
            </div>
            {/* <Typography.Text
              style={{ fontSize: 16, fontWeight: "bold", color: "white" }}
            >
              © 2023 TIGARESI BANGUN NUSAPERDANA
            </Typography.Text> */}
          </Footer>
        </Affix>
      </Layout>
    </Layout>
  );
}

export default Sidebar;
