import { SearchOutlined } from "@ant-design/icons";
import React, { useRef, useState, useEffect } from "react";
import Highlighter from "react-highlight-words";
import { Button, Input, Space, Table, Tag, Modal } from "antd";

function TablePenunjang({ data }) {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const dataTable = [];
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#DAFFFB" : "white",
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    // {
    //   title: "Poli",
    //   dataIndex: "poli",
    //   key: "poli",
    //   width: "100px",
    //   ...getColumnSearchProps("poli"),
    //   fixed: "left",
    //   sorter: (a, b) => a.id - b.id,
    //   sortDirections: ["descend", "ascend"],
    //   defaultSortOrder: "descend",
    // },
    {
      title: "Tanggal",
      dataIndex: "tanggal",
      key: "tanggal",
      width: "200px",
      ...getColumnSearchProps("tanggal"),
      fixed: "left",
      sorter: (a, b) => a.id - b.id,
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "descend",
    },
    {
      title: "Nama Dokter",
      dataIndex: "nama_dokter",
      key: "nama_dokter",
      width: "200px",
      fixed: "left",
      ...getColumnSearchProps("nama_dokter"),
    },

    {
      title: "Total",
      dataIndex: "empatpuluh_persen",
      key: "empatpuluh_persen",
      width: "200px",
      align: "center",
      // fixed: "left",
      // ...getColumnSearchProps("alkes"),
    },

    // {
    //   "id": 13,
    //   "poli": "Poli Anak",
    //   "labor": "800000.00",
    //   "total": "1698000.00",
    //   "konsul": "80000.00",
    //   "jm_dpjp": "285264.00",
    //   "rontgen": "800000.00",
    //   "tanggal": "2023-11-01",
    //   "tindakan": "8000.00",
    //   "alkes_obat": "10000.00",
    //   "rawat_inap": "tidak",
    //   "biaya_pokok": "1610000.00",
    //   "jnm_perawat": "12225.60",
    //   "lima_persen": "84400.00",
    //   "nama_dokter": "dr.ana",
    //   "nama_pasien": "adam",
    //   "pendaftaran": "20000.00",
    //   "pejabat_blud": "54336.00",
    //   "jasa_langsung": "407520.00",
    //   "jnm_penunjang": "0.00",
    //   "pos_remumerasi": "135840.00",
    //   "sepuluh_persen": "168800.00",
    //   "staf_stuktural": "54336.00",
    //   "pendapatan_biru": "0.00",
    //   "empatpuluh_persen": "679200.00",
    //   "pejabat_stuktural": "27168.00",
    //   "jasa_tidaklangsung": "271680.00",
    //   "jm_dokterpenunjang": "0.00",
    //   "jenis_bedah_nonbedah": "nonbedah",
    //   "penunjang_nonpenunjang": "nonpenunjang"
    // },
    {
      title: "Jasa Langsung",
      dataIndex: "jasa_langsung",
      key: "jasa_langsung",
      width: "200px",
      align: "center",
    },
    {
      title: "Jasa Tidak Langsung",
      dataIndex: "jasa_tidaklangsung",
      key: "jasa_tidaklangsung",
      width: "200px",
      align: "center",
    },
    {
      title: "Jasa Medis DPJP",
      dataIndex: "jm_dpjp",
      key: "jm_dpjp",
      width: "200px",
      align: "center",
    },
    {
      title: "Jasa Medis Dokter Penunjang",
      dataIndex: "jm_dokterpenunjang",
      key: "jm_dokterpenunjang",
      width: "200px",
      align: "center",
    },
    {
      title: "Jasa Non Medis Perawat",
      dataIndex: "jnm_perawat",
      key: "jnm_perawat",
      width: "200px",
      align: "center",
    },
    {
      title: "Jasa Non Medis Penunjang",
      dataIndex: "jnm_penunjang",
      key: "jnm_penunjang",
      width: "200px",
      align: "center",
    },
    {
      title: "Pejabat BLUD",
      dataIndex: "pejabat_blud",
      key: "pejabat_blud",
      width: "200px",
      align: "center",
    },
    {
      title: "Pejabat Struktural",
      dataIndex: "pejabat_stuktural",
      key: "pejabat_stuktural",
      width: "200px",
      align: "center",
    },
    {
      title: "Staf Struktural",
      dataIndex: "staf_stuktural",
      key: "staf_stuktural",
      width: "200px",
      align: "center",
    },
    {
      title: "Pos Remunerasi",
      dataIndex: "pos_remumerasi",
      key: "pos_remumerasi",
      width: "200px",
      align: "center",
    },
  ];
  return (
    <Table
      columns={columns}
      dataSource={data}
      rowKey={(record) => {
        return record.id;
      }}
      scroll={{
        x: 1300,
      }}
      pagination={{
        showTotal: (total, range) =>
          `${range[0]}-${range[1]} of ${total} datas`,
        onChange: (e) => {
          // console.log(e);
        },
      }}
      loading={isLoading}
    />
  );
}

export default TablePenunjang;
