import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
} from "antd";
import React, { useState } from "react";
import { ApiClient } from "../../api/ApiClient";
import axios from "axios";

function FormPoli({ getDataTable, poli }) {
  const [form] = Form.useForm();
  const [date, setDate] = useState("");

  const onFinish = (data) => {
    // axios
    //   .get(
    //     "http://192.168.0.174:8080/api/w/hospital/jobs/run_wait_result/f/u/tigaresi/delicate_flow?payload=eyJkYXRhIjp7ImRldmljZSI6ImQifX0%3D",
    //     {
    //       headers: {
    //         Authorization: "Bearer fdb9qNO3qVEc5hnVvntY4ySSbIK8fR",
    //         "Content-Type": "application/json",
    //       },
    //     }
    //   )
    //   .then((res) => {
    //     console.log(res);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    data.poli = poli;
    data.tanggal = date;
    // http://192.168.0.174:8080/api/w/hospital/jobs/run/f/u/tigaresi/poli_anak_baru
    ApiClient()
      .post("/run/f/u/tigaresi/poli_anak_baru", { data: data })
      .then((res) => {
        console.log(res);
        setTimeout(() => {
          getDataTable();
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
      });
    //192.168.0.174:8080/api/w/hospital/jobs/run_wait_result/f/u/tigaresi/delicate_flow?payload=eyJkYXRhIjp7ImRldmljZSI6ImQifX0%3D

    // data.total =
    //   data.konsul + data.labor + data.alkes + data.rontgen + data.tindakan;
    // data.bpo = data.labor + data.alkes + data.rontgen;
    // data.lima_percent = (data.total - data.alkes) * 0.05;
    // data.sepuluh_percent = (data.total - data.alkes) * 0.1;
    // data.empatpuluh_percent = data.total * 0.4;

    // setDataForm((oldData) => [...oldData, data]);

    console.log(data);
  };
  return (
    <>
      <Form
        labelCol={{
          span: 12,
        }}
        wrapperCol={{
          span: 12,
        }}
        form={form}
        // onFinish={updatePassword}
        layout="horizontal"
      >
        <Row gutter={[30, 30]} align={"middle"}>
          <Col span={14}>
            {/* <Form.Item
              label="Poli"
              name="poli"
              initialValue="Poli Anak"
              rules={[
                {
                  required: true,
                  message: "Tolong Masukan Tanggal Periksa !",
                },
              ]}
            >
              <Input disabled={true}></Input>
            </Form.Item> */}
            <Form.Item
              label="Tanggal"
              name="tanggal"
              rules={[
                {
                  required: true,
                  message: "Tolong Masukan Tanggal Periksa !",
                },
              ]}
            >
              <DatePicker
                onChange={(date, datestring) => {
                  setDate(datestring);
                  //   return datestring;
                  //   console.log(date, datestring);
                }}
              />
            </Form.Item>
            <Form.Item
              label="Nama Dokter"
              name="nama_dokter"
              rules={[
                {
                  required: true,
                  message: "Tolong Masukan Nama Dokter !",
                },
              ]}
            >
              <Input style={{ width: 200 }} />
            </Form.Item>
            <Form.Item
              label="Nama Pasien"
              name="nama_pasien"
              rules={[
                {
                  required: true,
                  message: "Tolong Masukan Nama Paseien !",
                },
              ]}
            >
              <Input style={{ width: 200 }} />
            </Form.Item>
            <Form.Item
              label="Bedah / Non Bedah"
              name="jenis_bedah_nonbedah"
              rules={[
                {
                  required: true,
                  message: "Tolong Masukan Tanggal Periksa !",
                },
              ]}
            >
              <Select>
                <Select.Option key={"bedah"}>BEDAH</Select.Option>
                <Select.Option key={"nonbedah"}>NON BEDAH</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Penunjang / Non Penunjang"
              name="penunjang_nonpenunjang"
              rules={[
                {
                  required: true,
                  message: "Tolong Masukan Tanggal Periksa !",
                },
              ]}
            >
              <Select>
                <Select.Option key={"penunjang"}>PENUNJANG</Select.Option>
                <Select.Option key={"nonpenunjang"}>
                  NON PENUNJANG
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Rawat Inap"
              name="rawat_inap"
              rules={[
                {
                  required: true,
                  message: "Tolong Masukan Tanggal Periksa !",
                },
              ]}
            >
              <Select>
                <Select.Option key={"ya"}>YA</Select.Option>
                <Select.Option key={"tidak"}>TIDAK</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              label="Biaya Pendaftaran"
              name="pendaftaran"
              initialValue={0}
              rules={[
                {
                  required: true,
                  message: "Tolong Masukan Biaya Pendaftaran !",
                },
              ]}
            >
              <InputNumber style={{ width: 200 }} />
            </Form.Item>
            <Form.Item
              label="Biaya Konsul"
              name="konsul"
              initialValue={0}
              rules={[
                {
                  required: true,
                  message: "Tolong Masukan Biaya Konsul !",
                },
              ]}
            >
              <InputNumber style={{ width: 200 }} />
            </Form.Item>
            <Form.Item
              label="Biaya Tindakan"
              name="tindakan"
              initialValue={0}
              rules={[
                {
                  required: true,
                  message: "Tolong Masukan Biaya Tindakan !",
                },
              ]}
            >
              <InputNumber style={{ width: 200 }} />
            </Form.Item>

            <Form.Item
              label="Biaya Labor"
              name="labor"
              initialValue={0}
              rules={[
                {
                  required: true,
                  message: "Tolong Masukan Biaya Labor !",
                },
              ]}
            >
              <InputNumber style={{ width: 200 }} />
            </Form.Item>
            <Form.Item
              label="Biaya Rontgen"
              name="rontgen"
              initialValue={0}
              rules={[
                {
                  required: true,
                  message: "Tolong Masukan Biaya Rontgen !",
                },
              ]}
            >
              <InputNumber style={{ width: 200 }} />
            </Form.Item>
            <Form.Item
              label="Biaya Alkes"
              name="alkes"
              initialValue={0}
              rules={[
                {
                  required: true,
                  message: "Tolong Masukan Biaya Alkes/Obat !",
                },
              ]}
            >
              <InputNumber style={{ width: 200 }} />
            </Form.Item>
          </Col>
        </Row>

        <Row
          // align="middle"
          style={{
            paddingLeft: "15px",
            paddingBottom: "10px",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <Space size="middle">
            <Button type="default"> CLEAR</Button>

            <Button
              type="primary"
              onClick={() => {
                onFinish(form.getFieldsValue());
              }}
            >
              SAVE
            </Button>
          </Space>
        </Row>
      </Form>
    </>
  );
}
export default FormPoli;
