import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Checkbox,
  Col,
  Form,
  Image,
  Input,
  Layout,
  Row,
  Space,
  Typography,
  notification,
} from "antd";
import { LockTwoTone, SmileTwoTone, UserOutlined } from "@ant-design/icons";
import { Content } from "antd/lib/layout/layout";
import { jwtDecode } from "jwt-decode";
import Background from "../components/shared/Background";
// import FormForgotPassword from "../components/user/FormForgotPassword";
import { ApiClient } from "../api/ApiClient";

const formItemLayout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: "100%",
  },
};

function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isModalResetPassword, setIsModalResetPassword] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type, message, description) => {
    // console.log(type, message, description);
    api[type]({
      message: message,
      description: description,
    });
  };
  const navigate = useNavigate();

  const login = async () => {
    ApiClient()
      .post(`/auth/login/email?channel=user`, {
        email: email,
        password: password,
      })
      .then((res) => {
        openNotificationWithIcon("success", "Login Success", res.message);
        const token = res.data.auth.token;

        const decode = jwtDecode(token);
        localStorage.setItem("refresh", res.data.auth.refresh_token);
        localStorage.setItem("token", token);
        localStorage.setItem("credent", decode.RV);
        localStorage.setItem("uid", res.data.user.uid);
        localStorage.setItem("sid", res.data.user.sid);
        localStorage.setItem("fn", res.data.user.full_name);

        // if (decode.RV === 2) {
        //   // console.log("/");
        //   navigate("/account-list");
        // } else {
        //   navigate("/");

        //   // console.log("/account");
        // }
        // setTimeout(() => {
        //   window.location.reload(false);
        // }, 500);
        // console.log(res.data.message);
      })
      .catch((err) => {
        openNotificationWithIcon(
          "error",
          "Failed Login",
          err.response.data.message
        );
      });
  };
  return (
    <div className="load-content">
      {contextHolder}
      <Layout>
        <Content
          align="middle"
          justify="center"
          style={{
            flexDirection: "column",
            // minHeight: "100vh",
            // backgroundColor: "#64CCC5",
            backgroundImage: `url(${process.env.PUBLIC_URL + "/background.jpg"}
            )`,
            backgroundRepeat: "no-repeat",
          }}
        >
          <Row align="middle" justify="center" style={{ padding: "0px" }}>
            <Col>
              <Typography.Title
                level={8}
                style={{
                  color: "white",
                  alignItems: "center",
                  justifyContent: "center",
                  justifyItems: "center",
                  flexDirection: "column",
                }}
              >
                Smart monitoring TBN
              </Typography.Title>
            </Col>
          </Row>

          {/* <Background /> */}

          <Row
            align="middle"
            justify="center"
            style={{
              flexDirection: "column",
              minHeight: "93.5vh",
              // backgroundColor: "#64CCC5",
              //   backgroundImage: `url(${
              //     process.env.PUBLIC_URL + "/background.jpg"
              //   }
              // )`,
              //   backgroundRepeat: "no-repeat",
            }}
          >
            <Col></Col>
            <Col>
              <Card
                style={{
                  width: "100%",
                  placeContent: "center",
                  alignSelf: "center",
                  borderRadius: "10px",
                  backgroundColor: "#DAFFFB",
                }}
                type="flex"
                align="middle"
              >
                <Space
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "baseline",
                    paddingBottom: "10px",
                  }}
                >
                  {/* <UserOutlined style={{ fontSize: 20 }} /> */}
                  <Typography.Title level={4}>LOGIN</Typography.Title>
                </Space>

                <Form
                  name="basic"
                  {...formItemLayout}
                  initialValues={{
                    remember: true,
                  }}
                  // onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <Form.Item
                    label="E-mail"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please input your email!",
                      },
                    ]}
                  >
                    <Input
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      prefix={<SmileTwoTone />}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Input.Password
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      prefix={<LockTwoTone />}
                    />
                  </Form.Item>

                  <Form.Item
                    name="remember"
                    valuePropName="checked"
                    wrapperCol={{
                      offset: 0,
                      span: 25,
                    }}
                  >
                    <Checkbox>Remember me</Checkbox>
                  </Form.Item>

                  <Form.Item
                    wrapperCol={{
                      offset: 0,
                      span: 50,
                    }}
                  >
                    <button
                      onClick={() => {
                        login();
                      }}
                      className="glow-on-hover"
                    >
                      LOGIN
                    </button>
                  </Form.Item>
                </Form>
                <Typography.Link
                  onClick={() => {
                    setIsModalResetPassword(true);
                  }}
                >
                  forgot password ?
                </Typography.Link>
              </Card>
            </Col>
          </Row>
        </Content>
      </Layout>
      {/* <FormForgotPassword
        setIsModalResetPassword={setIsModalResetPassword}
        isModalResetPassword={isModalResetPassword}
      /> */}
    </div>
  );
}

export default LoginPage;
